import { useCallback, FC } from 'react';
import { CalendarCore } from './calendar-core';
import { convertDateToSingleFormat } from '../../../common/utils/convertDateToSingleFormat';

// TODO any, DatesMap
export const Calendar: FC<any> = ({
  datesToMap,
  selectedDayValue,
  setSelectedDay,
}) => {
  const getDayData = useCallback(
    (day: string) => {
      const dayInTheMap = datesToMap[convertDateToSingleFormat(day)];
      const selected =
        convertDateToSingleFormat(selectedDayValue) ===
        convertDateToSingleFormat(day);
      const dayData = {
        selected,
        setSelected: () => setSelectedDay(selected ? null : day),
        ...(dayInTheMap || {}),
      };
      return dayData;
    },
    [datesToMap, selectedDayValue, setSelectedDay]
  );

  return <CalendarCore getDayData={getDayData} />;
};
