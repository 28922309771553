import { Heading, VStack } from '@chakra-ui/react';
import { GoogleIcon, SocialButton } from '../../common';
import { useAuthWithSocial } from '../presenter';

export const Login = () => {
  const { signInWithGoogle } = useAuthWithSocial();
  return (
    <VStack spacing="12">
      <Heading fontSize="xl" fontWeight="semibold">
        Welcome back
      </Heading>
      <SocialButton onClick={signInWithGoogle}>
        <GoogleIcon w="24px" h="24px" mr="6" />
        Login with Google
      </SocialButton>
    </VStack>
  );
};
