import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { ONBOARDING_IMG_URL } from '../../common';
import { OnboardingHeader } from '../components/onboarding-header';

export const OnboardingContainer = () => (
  <Flex
    w="100%"
    h="100%"
    position="relative"
    justifyContent="space-between"
    alignItems="center"
    bg={`url('${ONBOARDING_IMG_URL}')`}
    backgroundPosition={{ base: '28% 50%', md: 'center' }}
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    direction="column"
  >
    <OnboardingHeader />
    <Flex
      w="100%"
      maxW="32rem"
      pt="10"
      pb="12"
      px="4"
      bgColor="card"
      borderRadius={{ base: '20px 20px 0 0', md: '20px' }}
      justifyContent="center"
      border="1px solid rgba(255,255,255,0.05)"
      my={{ md: 'auto' }}
    >
      <Box maxW="320px" w="100%">
        <Outlet />
      </Box>
    </Flex>
  </Flex>
);
