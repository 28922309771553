import { Box, GridItem } from '@chakra-ui/react';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../common';
import {
  BackButton,
  ProfilePageContainer,
  ProfilePageHeader,
  SettingsBlock,
  SettingsLink,
} from '../components';

export const ProfileSettings = () => {
  return (
    <>
      <GridItem>
        <ProfilePageHeader title="Settings" LeftButton={<BackButton />} />
      </GridItem>
      <GridItem>
        <ProfilePageContainer>
          <Box w="100%">
            <SettingsBlock>
              <SettingsLink href="https://support.hoa.plus/en/" isExternal>
                Support
              </SettingsLink>
              <SettingsLink href={TERMS_OF_USE} isExternal>
                Terms &amp; Conditions
              </SettingsLink>
              <SettingsLink href={PRIVACY_POLICY} isExternal mb="0">
                Privacy Policy
              </SettingsLink>
            </SettingsBlock>
          </Box>
        </ProfilePageContainer>
      </GridItem>
    </>
  );
};
