import * as Components from './calendar-styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState, FC } from 'react';
import { Heading, Icon } from '@chakra-ui/react';
import { CalendarDayCellProps } from './calendar-styled-components';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

// Start from Monday
dayjs.Ls.en.weekStart = 1;

const arrowStyles = {
  cursor: 'pointer',
  color: 'sand.0',
  opacity: 0.6,
  w: 7,
  h: 12,
  transition: 'opacity 250ms linear 0ms',

  _hover: {
    opacity: 1,
  },
};

type DayProps = {
  selectedDate: Dayjs;
  day: Date;
  currentDay: Date;
  getDayData: (day: Date) => Omit<CalendarDayCellProps, 'variant'>;
};

export const Day: FC<DayProps> = ({
  selectedDate,
  day,
  currentDay,
  getDayData,
}) => {
  const variant =
    selectedDate.clone().toDate().getMonth() !== day.getMonth()
      ? 'nextMonth'
      : dayjs(currentDay).isSame(day, 'date')
      ? 'today'
      : 'default';

  return (
    <Components.CalendarDayCell
      variant={variant}
      {...(variant !== 'nextMonth' ? getDayData(day) : { selected: false })}
    >
      {day.getDate()}
    </Components.CalendarDayCell>
  );
};

// TODO any
export const CalendarCore: FC<any> = ({ getDayData = () => ({}) }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf('month'),
    [selectedDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf('week'),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day: Dayjs): Dayjs[] => {
    const dates: Dayjs[] = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, 'week');
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day: Dayjs): Date[] => {
    const dates: Date[] = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, 'day').toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo((): Date[][] => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const onPreviousMonthClick = () =>
    setSelectedDate((date) => date.subtract(1, 'month'));
  const onNextMonthClick = () =>
    setSelectedDate((date) => date.add(1, 'month'));

  /* TODO Make it grid */
  return (
    <Components.MainWrapper>
      <Components.CalendarHeaderWrapper>
        <Heading as="h2" variant="calendar-h2">
          {selectedDate.clone().format('MMMM YYYY')}
        </Heading>
        <div>
          <Icon
            as={IoChevronBack}
            {...arrowStyles}
            onClick={onPreviousMonthClick}
          />
          <Icon
            as={IoChevronForward}
            {...arrowStyles}
            marginRight="-9px"
            onClick={onNextMonthClick}
          />
        </div>
      </Components.CalendarHeaderWrapper>
      <Components.WeekDaysWrapper>
        {generateWeeksOfTheMonth[0].map((day, index) => (
          <Components.WeekDayCell key={`week-day-${index}`}>
            {dayjs(day).format('ddd')}
          </Components.WeekDayCell>
        ))}
      </Components.WeekDaysWrapper>
      {generateWeeksOfTheMonth.map((week, weekIndex) => (
        <Components.CalendarContentWrapper key={`week-${weekIndex}`}>
          {week.map((day, dayIndex) => (
            <Day
              key={day.toString()}
              selectedDate={selectedDate}
              currentDay={currentDay}
              day={day}
              getDayData={getDayData}
            />
          ))}
        </Components.CalendarContentWrapper>
      ))}
    </Components.MainWrapper>
  );
};
