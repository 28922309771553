import { useMemo, useState } from 'react';
import { DesiredSchedule } from '../model/types';
import dayjs from 'dayjs';
import { useCoaches } from '../../common/model';
import { useWeeksData } from '../../common/model/weeks/use-weeks';
import { isDayInFuture } from '../../common/utils/isDayInFuture';
import { ClassSession } from '../../common/types/class';
import { useClassHistoryData } from './use-class-history';
import { convertDateToSingleFormat } from '../../common/utils/convertDateToSingleFormat';
import { useClientContext } from '../../chat/model/ClientProvider';

export const useNormalizedScheduleData = (
  id?: string
): (DesiredSchedule & ClassSession)[] => {
  const { data: weeks, loading, error } = useWeeksData(id);

  const {
    data: coaches,
    loading: coachesLoading,
    error: coachesError,
  } = useCoaches();
  const {
    data: classHistory,
    loading: classHistoryLoading,
    error: classHistoryError,
  } = useClassHistoryData();

  if (
    loading ||
    error ||
    coachesLoading ||
    coachesError ||
    coachesLoading ||
    coachesError ||
    classHistoryLoading ||
    classHistoryError
  ) {
    return [];
  }

  if (!weeks || !coaches || !classHistory) {
    return [];
  }

  const normalizedData = weeks!.reduce((aggr, week) => {
    const weekClassesNormalized: (DesiredSchedule & ClassSession)[] =
      week.classes.map((training) => {
        // @ts-ignore
        const newTraining: DesiredSchedule & ClassSession = { ...training };
        const dayInDayJs = dayjs(training.scheduledAt).startOf('day');

        // prepare day
        newTraining.day = dayInDayJs.isToday()
          ? 'Today'
          : dayInDayJs.isYesterday()
          ? 'Yesterday'
          : dayInDayJs.format('dddd DD MMM');

        // prepare coach
        // @ts-ignore // TODO
        newTraining.coach = coaches?.find(
          (coach) => coach.id === training.coach
        );

        // prepare date
        newTraining.date = training.scheduledAt;

        // prepare day result COMPLETED / UNCOMPLETED
        newTraining.dayResult = training.completed
          ? 'COMPLETED'
          : 'UNCOMPLETED';

        // prepare isFuture flag
        newTraining.isDayInFuture = isDayInFuture(training);

        // prepare class history for this class
        newTraining.classHistory = classHistory.find(
          (classHistoryElem) => classHistoryElem.classId === training.id
        );

        return newTraining;
      });

    aggr.push(...weekClassesNormalized);

    return aggr;
  }, [] as (DesiredSchedule & ClassSession)[]);

  return normalizedData;
};

type DatesMap = {
  [key: string]: any;
};

export const useScheduleData = () => {
  const { clientId } = useClientContext();
  const schedule = useNormalizedScheduleData(clientId);

  const [selectedDayValue, setSelectedDay] = useState(null);

  const datesToMap = useMemo(
    () =>
      schedule.reduce((aggr: DatesMap, next: DatesMap) => {
        aggr[convertDateToSingleFormat(next.date)] = next;
        return aggr;
      }, {} as DatesMap),
    [schedule]
  );

  return {
    schedule,
    selectedDayValue,
    setSelectedDay,
    datesToMap,
  };
};
