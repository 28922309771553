import { Grid } from '@chakra-ui/react';
import { Logo } from '../../common';

export const OnboardingHeader = () => (
  <Grid
    templateColumns="1fr"
    alignItems="center"
    pt="4vh"
    px={{ base: 4, md: 12 }}
    position="relative"
    top="0"
    left="0"
    zIndex="docked"
    w="100%"
  >
    <Logo
      color="white"
      w={{ base: '7rem', md: '10rem' }}
      h="auto"
      justifySelf="center"
    />
  </Grid>
);
