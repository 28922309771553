import { FC } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { StyleProps } from '@chakra-ui/styled-system';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { AvatarLinesFeed } from './avatar-lines-feed';
import { DesiredSchedule, TFeedItemContainer } from '../../model/types';
import { convertDateToSingleFormat } from '../../../common/utils/convertDateToSingleFormat';
import { useClassTypeData } from '../../presenter';
import { ClassSession } from '../../../common/types/class';

export const getTime = (currentTime: number) => {
  const seconds = String(Math.floor(currentTime % 60));
  return `${parseInt(String(currentTime / 60))}:${seconds.padStart(2, '0')}`;
};

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const FeedItemContainer: FC<TFeedItemContainer> = ({
  coach,
  day = '',
  withoutBackLine,
  ...props
}) => (
  <HStack spacing="10px" alignItems="stretch" pb="60px">
    <AvatarLinesFeed coach={coach} withoutBackLine={withoutBackLine} />
    <VStack alignItems="flex-start">
      <Text as="h3" fontSize="xs" color="white">
        {day}
      </Text>
      <FeedItem day={day} coach={coach} key={props.id} {...props} />
    </VStack>
  </HStack>
);

const FeedItem: FC<DesiredSchedule & ClassSession> = ({
  type,
  duration,
  description,
  classHistory,
}) => {
  const { data: typeData } = useClassTypeData(type);

  return (
    <VStack alignItems="flex-start" spacing={2}>
      <Text casing="uppercase" color="sand.0" fontWeight={500}>
        {typeData && `${typeData.name.toUpperCase()} ${duration}`}
      </Text>
      <Text fontSize="xl" color="stone.300" fontWeight={500} noOfLines={1}>
        {description.short}
      </Text>
      {classHistory ? (
        <Text fontSize="sm" color="stone.300">
          {/* TODO calories */}
          {getTime(classHistory.activeTime)} min
        </Text>
      ) : null}
    </VStack>
  );
};

type ScheduleFeedType = {
  data: (DesiredSchedule & ClassSession)[];
  selectedDayValue: null | Date;
} & StyleProps;

const ScheduleFeed = ({
  data,
  selectedDayValue,
  ...props
}: ScheduleFeedType) => {
  const daysInMap = selectedDayValue
    ? data.filter(
        (dayData) =>
          convertDateToSingleFormat(dayData.scheduledAt) ===
          convertDateToSingleFormat(selectedDayValue)
      )
    : null;

  return (
    <VStack alignItems="flex-start" {...props}>
      {selectedDayValue ? (
        daysInMap && daysInMap.length ? (
          daysInMap.map(
            (dayData: DesiredSchedule & ClassSession, index: number) => (
              <FeedItemContainer
                key={dayData.id}
                withoutBackLine={index === 0}
                {...dayData}
              />
            )
          )
        ) : (
          <Box>
            You did not complete any classes on{' '}
            {dayjs(selectedDayValue).format('MMMM, D')}
          </Box>
        )
      ) : (
        data.map((dayData: DesiredSchedule & ClassSession, index: number) => (
          <FeedItemContainer
            key={dayData.id}
            withoutBackLine={index === 0}
            {...dayData}
          />
        ))
      )}
    </VStack>
  );
};

export default ScheduleFeed;
