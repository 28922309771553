import { useToast } from '@chakra-ui/react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { isCoach } from '../../common';
import { useStore } from '../../common/model';
import { auth } from '../../firebase';

const EMAIL_WHITELIST = ['@houseofathlete.com', '@hoa.plus'];

export const useAuthCheck = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState<string>('pending');
  const toast = useToast();

  // @TODO we should show error to user
  const setErrorFn = (error: any) => {
    setError(error);
    setStatus('rejected');
  };

  useEffect(() => {
    const listener = onAuthStateChanged(
      auth,
      async (user) => {
        const result = await user?.getIdTokenResult();

        const coach = isCoach(result?.claims.roles || []);
        console.log(result?.claims.roles);

        const isInternalEmail = EMAIL_WHITELIST.some(
          (email) => user?.email?.indexOf(email) !== -1
        );

        setUser(user);

        if (!coach || !isInternalEmail) {
          // We need to sign out user who is not a coach or its email isn't internal
          await signOut(auth);
          const description = coach
            ? 'The email should be an @houseofathlete.com or @hoa.plus'
            : "You don't have the correct permissions";

          toast({
            description,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom',
          });
        }
        setStatus('resolved');
      },
      setErrorFn
    );

    return () => {
      listener();
    };
  }, [setUser, toast]);

  return { user, error, isPending: status === 'pending' };
};
