import { StateCreator } from 'zustand';
import { produce } from 'immer';
import { User } from 'firebase/auth';

export interface UserHealth {
  weight?: number;
  height?: string;
  dateOfBirth?: string;
}

export interface Subscription {
  status: 'neverActivated' | 'inactive' | 'active' | 'canceled' | 'expired';
  platform?: 'web' | 'ios';
  type?: 'coaching' | 'personalTraining';
}

export interface UserInfo {
  id?: string;
  name?: string;
  avatar?: string;
  email?: string;
  onboarding?: any;
  health?: UserHealth;
  subscription?: Subscription;
  popups?: Record<string, boolean>;
}

export interface UserSlice {
  user: User | null;
  userInfo: UserInfo | null;
  roles: string[];
  setUser(user: User | null): void;
  setUserInfo(userInfo: UserInfo | null): void;
  setRoles(roles: string[]): void;
}

export const userSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  user: null,
  userInfo: null,
  roles: [],
  setUser(user) {
    set(
      produce((state) => {
        state.user = user;
      })
    );
  },
  setUserInfo(userInfo) {
    set(
      produce((state) => {
        state.userInfo = userInfo;
      })
    );
  },
  setRoles(roles) {
    set(
      produce((state) => {
        state.roles = roles;
      })
    );
  },
});
