import { useToast } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { debounce } from '../../common';
import { auth } from '../../firebase';

const TEN_MINUTES = 1000 * 60 * 10;

export const useActivityTracking = () => {
  const toast = useToast();
  useEffect(() => {
    const onSignOut = () => {
      signOut(auth);
      toast({
        description:
          'Hello, it looks like you have been inactive for 10 minutes. For security reasons, you was logged out',
        status: 'info',
        duration: null,
        isClosable: true,
        position: 'bottom',
      });
    };
    let timer: any = null;

    const refreshTimeout = () => {
      timer = clearTimeout(timer);
      timer = setTimeout(onSignOut, TEN_MINUTES);
    };

    const debouncedRefresh = debounce(refreshTimeout, 500);

    document.addEventListener('keydown', refreshTimeout);
    document.addEventListener('mousedown', refreshTimeout);
    document.addEventListener('mousemove', debouncedRefresh);
    refreshTimeout();

    return () => {
      document.removeEventListener('keydown', refreshTimeout);
      document.removeEventListener('mousedown', refreshTimeout);
      document.removeEventListener('mousemove', debouncedRefresh);
    };
  }, []);
};
