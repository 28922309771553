import { Box, useStyleConfig } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { ClassSession } from '../../../common/types/class';
import { DesiredSchedule } from '../../model/types';

type Styles = {
  [key: string]: string | number | object;
};

type Props = {
  [key: string]: any;
};

export const getStandardComponent =
  (styles: Styles) =>
  ({ children, ...props }: Props) =>
    (
      <Box {...styles} {...props}>
        {children}
      </Box>
    );

export const MainWrapper = getStandardComponent({
  minW: 360,
  p: { base: 0, md: 5 },
  paddingLeft: { md: 0 },
});

export const CalendarHeaderWrapper = getStandardComponent({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const WeekDaysWrapper = getStandardComponent({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  mt: 30,
});

export const WeekDayCell = getStandardComponent({
  fontWeight: 500,
  fontSize: 'xs',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'clay.0',
  width: '40px',
  margin: 1.5,
  textTransform: 'uppercase',
});

export const CalendarContentWrapper = getStandardComponent({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

export type CalendarDayCellProps = {
  variant: string;
  selected: boolean;
  setSelected?: () => void;
  dayResult?: 'COMPLETED' | 'UNCOMPLETED';
  isDayInFuture?: boolean;
} & Partial<ClassSession> &
  Partial<DesiredSchedule>;

export const CalendarDayCell: FC<PropsWithChildren<CalendarDayCellProps>> = (
  props
) => {
  const { variant, dayResult, isDayInFuture, selected, setSelected, children } =
    props;

  const variantToSet =
    selected && variant === 'today'
      ? 'selected-today'
      : selected
      ? 'selected'
      : isDayInFuture
      ? 'future'
      : dayResult
      ? `${variant}-${dayResult}`
      : variant;

  const styles = useStyleConfig('CalendarDayCell', {
    variant: variantToSet,
  });

  return <Box __css={styles} onClick={setSelected} children={children} />;
};
