import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, Fragment } from 'react';
import { useCoachesDrawer } from '../presenter';

const DrawerWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isListDrawerOpen, onListDrawerClose } = useCoachesDrawer();

  return (
    <Drawer
      isOpen={isListDrawerOpen}
      placement="left"
      onClose={onListDrawerClose}
      size="full"
    >
      <DrawerContent bg="onyx.0">
        <DrawerHeader>
          <DrawerCloseButton />
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
};

export const ClientsListWrapper: FC<PropsWithChildren> = ({ children }) => {
  const value = useBreakpointValue({
    base: 'drawer',
    md: null,
  });

  const Wrapper = value === null ? Fragment : DrawerWrapper;

  return <Wrapper>{children}</Wrapper>;
};
