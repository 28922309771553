import { Avatar, Box, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Coach } from '../../../common/types/coach';

type AvatarLinesFeedProps = {
  coach?: Coach;
  withoutBackLine?: boolean;
};

export const AvatarLinesFeed: FC<AvatarLinesFeedProps> = ({
  coach,
  withoutBackLine = false,
}) => {
  return (
    <VStack mt="8px" justifyContent="stretch">
      {Boolean(withoutBackLine) === false ? (
        <Box h="30px" w="2px" bg="sand.0" mt="-30px !important" />
      ) : null}
      <Avatar
        w={50}
        h={50}
        showBorder={true}
        name={`${coach?.firstName} ${coach?.lastName}`}
        src={coach?.avatarUrl}
        borderColor="sand.0"
        p="4px"
        mt="0 !important"
      />
      <Box
        minH="90px"
        h={'100%'}
        w="2px"
        bg="sand.0"
        mt="0 !important"
        mb="-100px !important"
      />
    </VStack>
  );
};
