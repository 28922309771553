import { Flex } from '@chakra-ui/react';
import type { StreamChat as StreamChatType } from 'stream-chat';
import {
  LoadingIndicator,
  Chat as StreamChat,
  Channel,
  MessageList,
  MessageInput,
  Window,
  ChannelList,
  InfiniteScroll,
} from 'stream-chat-react';
import { ChannelSearchFunctionParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';

import 'stream-chat-react/dist/css/v2/index.css';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import {
  ChatListPreview,
  ClientProgress,
  ClientsListWrapper,
  ExitSearchIcon,
  SendButton,
} from '../components';
import { ClientProvider } from '../model/ClientProvider';
import { useCoachesChat } from '../presenter';

const customSearchFunction = async (
  props: ChannelSearchFunctionParams<DefaultStreamChatGenerics>,
  event: React.BaseSyntheticEvent<object, any, any>,
  client: StreamChatType
) => {
  const { setResults, setSearching, setQuery } = props;
  const value = event.target.value || '';

  const filters = {
    name: { $autocomplete: value },
    members: { $in: [client.userID as string] },
  };

  try {
    setSearching(true);
    setQuery(value);
    const channels = await client.queryChannels(filters);
    setResults(channels);
    setSearching(false);
  } catch (error) {}
};

const Chat = () => {
  const { client, userId } = useCoachesChat();

  if (!client) return <LoadingIndicator />;

  return (
    <Flex w="100%" h="100%" alignItems="center">
      <StreamChat client={client}>
        <ClientsListWrapper>
          <ChannelList
            filters={{ members: { $in: [userId!] } }}
            sort={{ last_message_at: -1 }}
            showChannelSearch
            additionalChannelSearchProps={{
              ExitSearchIcon: ExitSearchIcon,
              searchFunction: (params, event) => {
                return customSearchFunction(params, event, client);
              },
            }}
            Paginator={InfiniteScroll}
            Preview={ChatListPreview}
          />
        </ClientsListWrapper>
        <Channel SendButton={SendButton}>
          <Window>
            <MessageList closeReactionSelectorOnClick />
            <MessageInput />
          </Window>
        </Channel>
      </StreamChat>
      <ClientProgress />
    </Flex>
  );
};

export const CoachesChat = () => (
  <ClientProvider>
    <Chat />
  </ClientProvider>
);
