import { Grid, GridItem } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { NAVIGATION_WIDTH } from '../constants';
import { MainNavigation } from '../components/main-navigation';

export const InternalLayout = () => (
  <Grid
    templateColumns={{ base: '1fr', md: `${NAVIGATION_WIDTH}px 1fr` }}
    templateRows={{
      base: `1fr ${NAVIGATION_WIDTH}px`,
      md: '1fr',
    }}
    height="100%"
  >
    <MainNavigation />
    <GridItem order={{ base: '-1', md: '2' }} minH="100%">
      <Outlet />
    </GridItem>
  </Grid>
);
