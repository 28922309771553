import { Box, Spinner } from '@chakra-ui/react';
import { useStore } from '../../common/model';
import { useActivityTracking } from '../presenter/useActivityTracking';
import { DummyPage, InternalLayout } from '../view';

export const PrivateRoutesNavigator = () => {
  const userInfo = useStore((state) => state.userInfo);
  useActivityTracking();

  if (!userInfo) {
    return (
      <Box w="100%" h="100%">
        <DummyPage>
          <Box
            fontSize="xl"
            color="white"
            display="flex"
            alignItems="center"
            mt="4"
          >
            Loading <Spinner ml="4" />
          </Box>
        </DummyPage>
      </Box>
    );
  }

  return <InternalLayout />;
};
