import { Button, Avatar, Flex, Box } from '@chakra-ui/react';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { FC, useEffect, useMemo, useState } from 'react';
// import { FC } from 'react';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import { firestore } from '../../firebase';
import { useClientContext } from '../model/ClientProvider';
import { useCoachesDrawer } from '../presenter/use-coaches-drawer';

async function asyncQuery(ref: DocumentReference, setter: (d: any) => void) {
  try {
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      setter(docSnap.data());
    }
  } catch (error) {
    console.log('ERROR: ', error);
    setter(null);
  }
}

export const ChatListPreview: FC<ChannelPreviewUIComponentProps> = ({
  channel,
  displayImage,
  displayTitle,
  setActiveChannel,
  unread,
  watchers,
}) => {
  const [user, setUser] = useState<any>(null);
  const { onListDrawerClose } = useCoachesDrawer();
  const { setClientId } = useClientContext();

  const userId = channel?.id?.split('-').pop() as string;
  const userRef = useMemo(() => doc(firestore, 'users', userId), [userId]);

  useEffect(() => {
    if (!userRef) return;
    asyncQuery(userRef, setUser);
  }, [userRef]);

  if (!user || user?.subscription?.status !== 'active') return null;

  const onSelect = () => {
    if (setActiveChannel) {
      setClientId(userId);
      setActiveChannel(channel, watchers);
    }
    onListDrawerClose();
  };

  const isCoaching = user.subscription.type === 'coaching';

  return (
    <Button
      variant="unstyled"
      onClick={onSelect}
      display="flex"
      color="white"
      w="100%"
      mb="2"
      px="1.5rem !important"
      fontWeight="light"
      h="auto"
      py="0.5rem !important"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Avatar
          bg="sand.0"
          color="onyx.0"
          w="1.5rem"
          h="1.5rem"
          src={displayImage}
          name={displayTitle}
          fontSize="1rem"
          size="sm"
          mr="4"
        />
        <Box
          w="4"
          h="4"
          mr="2"
          bgColor={isCoaching ? 'stone.200' : 'clay.400'}
          borderRadius="2px"
          fontSize="10px"
          lineHeight="16px"
          color="card"
          fontWeight="bold"
        >
          {isCoaching ? 'C' : 'PT'}
        </Box>
        {displayTitle}
      </Flex>
      {!!unread && (
        <Box
          w="1.25rem"
          h="1.25rem"
          borderRadius="50%"
          bgColor="sand.0"
          fontSize="xs"
          lineHeight="1.25rem"
        >
          {unread}
        </Box>
      )}
    </Button>
  );
};
