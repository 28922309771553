import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';

import { useStore, UserInfo } from '../../common/model';
import { firestore } from '../../firebase';

export const useUserInfo = () => {
  const user = useStore((state) => state.user);
  const setUserInfo = useStore((state) => state.setUserInfo);

  useEffect(() => {
    if (user) {
      const docRef = doc(firestore, 'users', user.uid as string);
      onSnapshot(docRef, {
        next(snapshot) {
          if (snapshot.exists()) {
            setUserInfo(snapshot.data() as UserInfo);
          } else {
            setUserInfo({ name: '' });
          }
        },
        error(error) {
          console.error(error);
        },
      });
    }
  }, [user, setUserInfo]);
};
