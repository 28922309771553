import { Button } from '@chakra-ui/react';

interface SocialButtonProps {
  children: React.ReactNode;
  onClick(): void;
}

export const SocialButton: React.FC<SocialButtonProps> = ({
  children,
  onClick,
}) => (
  <Button
    variant="outline"
    size="lg"
    onClick={onClick}
    borderRadius="8px"
    w="100%"
    px={4}
    _hover={{
      backgroundColor: 'stone.50',
      color: 'card',
    }}
  >
    {children}
  </Button>
);
