import { useBreakpointValue } from '@chakra-ui/react';
import { useMatch } from 'react-router-dom';
import { useCoachesDrawer } from '../../chat/presenter';

import { useStore } from '../../common/model';
import { ROUTES } from '../../router';

export const useMainNavigation = () => {
  const match = useMatch(ROUTES.PROFILE);
  const avatarPosition = useBreakpointValue({ base: 'fixed', md: 'static' });

  const { totalUnreadCount } = useStore((state) => state.chat);
  const { onListDrawerOpen, onProgressDrawerOpen } = useCoachesDrawer();

  return {
    match,
    avatarPosition,
    onListDrawerOpen,
    onProgressDrawerOpen,
    totalUnreadCount,
  };
};
