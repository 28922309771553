import create from 'zustand';
import { chatSlice, ChatSlice } from './chat-slice';
import { ProfileSlice, profileSlice } from './profile-slice';
import { trainingSlice, TrainingSlice } from './training-slice';
import { userSlice, UserSlice } from './user-slice';

type Store = ChatSlice & ProfileSlice & TrainingSlice & UserSlice;

export const useStore = create<Store>((...args) => ({
  ...chatSlice(...args),
  ...profileSlice(...args),
  ...trainingSlice(...args),
  ...userSlice(...args),
}));
