import { defineStyle } from '@chakra-ui/react';

export const H1_CONFIG = defineStyle({
  variants: {
    'hoa-h1': {
      color: 'clay.100',
      fontSize: 'xl', // TODO 20px but should be 22px
      fontWeight: 'normal',
    },
    'hoa-h1-md': {
      color: 'clay.100',
      fontSize: '2xl',
      fontWeight: 'normal',
    },
    'calendar-h2': {
      fontSize: 'xl',
      fontWeight: 500,
      color: 'white',
    },
  },
});
