import { createBrowserRouter } from 'react-router-dom';

import { CoachesChat } from '../../chat';
import { GlobalError } from '../../common';
import { PrivateRoutesNavigator } from '../../internal';
import {
  EditProfilePage,
  ProfilePage,
  ProfileRoot,
  ProfileSettings,
} from '../../profile';
import * as ROUTES from '../routes';

export const privateRouter = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <PrivateRoutesNavigator />,
    errorElement: <GlobalError />,
    children: [
      {
        index: true,
        element: <CoachesChat />,
      },
      {
        path: ROUTES.PROFILE,
        element: <ProfileRoot />,
        children: [
          {
            index: true,
            element: <ProfilePage />,
          },
          {
            path: ROUTES.EDIT_PROFILE,
            element: <EditProfilePage />,
          },
          {
            path: ROUTES.PROFILE_SETTINGS,
            element: <ProfileSettings />,
          },
        ],
      },
    ],
  },
]);
