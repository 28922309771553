import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Login } from '../../auth';
import { OnboardingContainer } from '../../onboarding';
import * as ROUTES from '../routes';

export const publicRouter = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <OnboardingContainer />,
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.ROOT} replace={true} />,
  },
]);
